<template>
  <view-container name="forgot-password">
    <view-content>
      <div data-vue-name="ForgotPassword" class="container-fluid authorization">
        <div class="authorization__title authorization__title_center">Restore Password</div>

        <form class="authorization__form" @submit.prevent="handleSubmit">
          <AuthInput
            v-model="user.email"
            @input="showEmailErrorBack = false"
            :valid="isValidMail"
            :placeholder="'Your Email'"
            :error="(showEmailErrorFront && !isValidMail) || showEmailErrorBack"
            :errorText="errorMessage || ''"
          />

          <CommonButton :type="'submit'" :caption="'Send'" class="submit-button" />
        </form>
      </div>
    </view-content>
  </view-container>
</template>

<script>
import { mapState } from 'vuex'
import AuthInput from '@/components/UI/AuthInput'
import CommonButton from '@/components/UI/CommonButton'

export default {
  name: 'ForgotPassword',
  components: { AuthInput, CommonButton },
  computed: {
    ...mapState({
      errors: ({ auth }) => auth.errors,
      message: ({auth}) => auth.message,
    }),
    isValidMail() {
      return this.validEmail(this.user.email)
    },
    emailErrorMsg() {
      return this.message
    },
    errorMessage() {
      return this.showEmailErrorFront
              ? this.emailErrorMessageFront
              : this.showEmailErrorBack
                  ? this.emailErrorMessageBack
                  : '';
    }
  },
  data() {
    return {
      user: {
        email: '',
      },
      showEmailErrorFront: false,
      showEmailErrorBack: false,
      emailErrorMessageFront: 'Incorrect email address',
      emailErrorMessageBack: '',
    }
  },
  methods: {
    handleSubmit() {
      this.showEmailErrorFront = false;
      this.showEmailErrorBack = false;

      if (!this.isValidMail) {
        if (!this.isValidMail) {
          this.showEmailErrorFront = true;
        }
        return
      }

      this.$store.dispatch('restorePassRequest', this.user)
        .then(() => {
          this.$router.push({
            name: 'message',
            params: { message: `We sent you a link to restore your password to ${this.user.email}` },
          })
          setTimeout(() => this.$router.push({ name: 'authorization' }), 3000)
        })
        .catch(error => {
          this.showEmailErrorBack = true;
          this.emailErrorMessageBack = error.response.data.message;
        })
    },
    handleGoogleSubmit() {
      this.$store.dispatch('loginWithGoogle').then(res => {
        window.location = res.redirect
      })
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
}
</script>

<style lang="stylus">
.danger {
  display block
  color red !important
  margin -8px 0 5px 0
}

.forgot-password-view {
  .view-content {
    padding 0 !important
  }
}

.authorization {
  height 100%
  width 100%
  background url("../assets/img/bg-page-auth-1.png") no-repeat
  background-size cover
  background-position: center;
  display flex
  flex-direction column
}

.authorization__title {
  font-family: 'Poppins', sans-serif;
  font-size 36px
  font-weight: bold;
  line-height 122%
  color: #EEEEEE;
  margin-top 96px
  margin-bottom 16px

  &_center {
    margin-top 200px
    text-align center
  }
}

.authorization__form {
  width 100%
  margin-bottom 72px
}

.authorization__form > div {
  position relative
}

.authorization__form i {
  position absolute
  z-index 10
  cursor: pointer;
  width 22px
  height 13px
  right 18px
  top 15px
}

.authorization__form input {
  width 100%
  background: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  margin-bottom 16px
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #D8D8D8 !important
  padding 13px 35px 13px 16px
}

.authorization__form input::placeholder {
  color: #7B8B97;
}

.authorization__form .btn-style1 {
  height 56px
  margin-top 8px
  margin-bottom 24px
}

.authorization__btn-style-link {
  color: #6293FF;
  margin-top 4px
  text-decoration none
}

.authorization__other-account {
  margin-bottom 75px
}

.authorization__other-account > div {
  margin-bottom 13px
}

.authorization__other-account button {
  background: #D8D8D8;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content center
  margin-bottom 16px
  width 100%
  height 40px
  font-family: 'Roboto', sans-serif

  svg {
    margin-right 24px
  }
}

.submit-button {
  margin: 40px 0 16px;
}
</style>
